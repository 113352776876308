/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import CardsImg from "@components/pageTicker/pageSolucionOmnicanal/components/cardImgTwo/cardImgTwo"
import UseCases from "@components/pageScore/pageContactCenterOmnicanal/components/useCases/useCases"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Start from "@components/pageFunctionalities/pageAlternativaWhatsapp/components/start"
import Compare from "@components/pageFunctionalities/pageAlternativaWhatsapp/components/compare"
import Integrations from "@components/pageScore/integrations/integrations"
import dataWhatsapp from "@components/pageChannels/pageWhatsapp/data"
import data from "@components/pageFunctionalities/pageAlternativaWhatsapp/data"
import InfoContact from "@components/pageFunctionalities/pageAlternativaWhatsapp/components/infoContact"
import Industries from "@components/pageChannels/pageWhatsapp/industries/industries"


const StructurePageMarketing = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <>
      <div className="fnd">
      <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/productos/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>
        <div className="container">
          <Start data={data.start} location={location} divisor={true} />
          <div className="divisor-cardTwo-pages-alternative-whatsapp" />
          <CardsImg
            data={data.cards}
            location={location}
            dataText={data.cardsText}
          />
          <BannerCTA
            title="¡Mejora tu comunicación con Beex!"
            text="Regístrate y empieza la mejor experiencia omnicanal. "
            link="/hablemos/"
            textButton="Crear mi cuenta en Beex"
          />
          <InfoContact data={data.infocontact} location={location} />
        <Industries data={dataWhatsapp.industries} />
          <Integrations location={location} />
          <Compare data={data.compare} />
          <BannerCTA
            title="¡Automatiza tu atención con WhatsApp!"
            text="Ofrece una experiencia única por medio de esta app."
            link="/hablemos/"
            textButton="Solicitar WhatsApp API"
          />
            <div>
            <UseCases
              data={data.modules}
              location={location}
              data1={data.useCases}
              alternative={true}
            />
          </div>
          <BannerContact data={data.contacts} location={location} link={true} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </div>
    </>
  )
}

export default StructurePageMarketing
