import React from "react"
import { Link } from "gatsby"
const AboutUs = ({ data, location }) => {
  return (
    <section className="background-image-pages-relative container-info-whatsapp">
      <div className="section-contact-whatsapp">
        <p className="container-info-whatsapp-tilte">{data.title}</p>
        <p className="text-info-whatsapp ">{data.textbody}</p>
        <Link
          href={data.link + location.search}
          className="link-whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.textlink}
        </Link>
      </div>
    </section>
  )
}

export default AboutUs
