/* eslint-disable react/jsx-key */
import React from "react"
import imgPortada from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/imgStart.png"
import imgSilder1 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/imgStart1.png"
import imgSilder2 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/imgStart2.png"

import card1 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/card1.png"
import card2 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/card2.png"
import card3 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/card3.png"
import card4 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/card4.png"

//iconos de funcion
import silder1 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/silder1.png"
import silder2 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/silder2.png"
import silder3 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/silder3.png"
import silder4 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/silder4.png"
import silder5 from "@components/pageFunctionalities/pageAlternativaWhatsapp/image/silder5.png"


const title = (
    <p className="guide__title">
        Accede a la API de WhatsApp Business oficial de Meta
    </p>
)

const textDescription = (
    <p>
        Descubre cómo la API de WhatsApp Business puede transformar la forma en que
        te comunicas con tus clientes y potenciar el crecimiento de tu negocio con
        funciones nunca antes vistas.
    </p>
)

const checked = (
    <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.5068 0.67906L9.91704 15.0584L3.4229 8.59475C2.64923 7.82192 1.38323 7.82192 0.586112 8.59475C-0.187558 9.36758 -0.187558 10.6322 0.586112 11.4285L8.5807 19.4144C8.95581 19.7891 9.47159 19.9999 9.98737 19.9999C10.0108 19.9999 10.0343 19.9999 10.0577 19.9999C10.6204 19.9765 11.1362 19.7423 11.4878 19.3207L25.4842 3.32543C26.211 2.48234 26.1172 1.24112 25.2967 0.491706C24.4995 -0.234289 23.2335 -0.140612 22.5068 0.67906Z"
            fill="#F7D731"
        />
    </svg>
)

const error = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_11620_7609)">
            <path
                d="M16.2269 19.3527L10 13.1257L3.7731 19.3527C2.9099 20.2158 1.51049 20.2158 0.647288 19.3527C-0.215763 18.4895 -0.215763 17.0901 0.647288 16.2269L6.87434 10L0.647288 3.7731C-0.215763 2.9099 -0.215763 1.51049 0.647288 0.647288C1.51049 -0.215763 2.9099 -0.215763 3.7731 0.647288L10 6.87434L16.2269 0.647288C17.0901 -0.215763 18.4895 -0.215763 19.3527 0.647288C20.2158 1.51049 20.2158 2.9099 19.3527 3.7731L13.1257 10L19.3527 16.2269C20.2158 17.0901 20.2158 18.4895 19.3527 19.3527C18.4895 20.2158 17.0901 20.2158 16.2269 19.3527Z"
                fill="#E76E54"
            />
        </g>
        <defs>
            <clipPath id="clip0_11620_7609">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const infoOne = [
    <p>{error}</p>,
    <p>Máximo 4</p>,
    <p>No puedes añadir chatbots.</p>,
    <p>{error}</p>,
    <p>{error}</p>,
]

const infoTwo = [
    <p>{checked}</p>,
    <p>Ilimitado</p>,
    <p>Puedes crear todos los chatbots que quieras.</p>,
    <p>{checked}</p>,
    <p>{checked}</p>,
]

const data = {
    start: {
        title: title,
        classNameTitle: "text-title-introone",
        textbody: textDescription,
        image: imgPortada,
        classNameIntro: "frame-introatencion",
        progress: "progressatencion",
        imageSilder: [imgSilder2, imgSilder1],
        textbutton: "¡Accede sin costo alguno!",
        link: "/whatsapp-business-api/contacto-whatsapp-api/",
    },
    cardsText: {
        title: <>Marca la diferencia con la API de WhatsApp Business de Meta</>,
        description: "",
    },

    cards: [card1, card2, card3, card4],
    infocontact: {
        title: "Costos de la API de WhatsApp Business de Meta",
        textbody:
            "No te preocupes por los costos de implementación, de eso nos encargamos nosotros. Solo tendrás que pagar por los mensajes que envíes. Por eso, te dejamos esta calculadora para que estimes tu inversión.",
        textbodytwo: "",
        textlink: "Conoce los precios por consumo",
        link: "/whatsapp-business-api/precios/",
    },
    banner: {
        title: "Intégralo con tus plataformas preferidas",
        text:
            "Accede a la API de WhatsApp Business de Meta desde Beex y adhiere tus sistemas de información como CRM o ERP.",
    },
    compare: [
        {
            img: "WhatsApp Business",
            info: infoOne,
            title: "ticker",
        },
        {
            img: "WhatsApp Business API",
            info: infoTwo,
            title: "twilio",
        },
    ],
    useCases: {
        title: (
            <>     ¿Por qué Beex?      </>
        ),
        description:
            "Puedes acceder a la API de WhatsApp Business de Meta desde Beex. ¡Conoce más de nuestra plataforma!",
    },
    modules: [
        {
            title: "",
            img: silder1,
            classtitle: "card-title-inbox",
            status: "Disponible",
            class: "texto-espacio",
            info:
                "Unifica todos tus canales de atención como WhatsApp, Facebook, Instagram y webchat.",
        },
        {
            title: "",
            classtitle: "card-title-inbox",
            img: silder2,
            info:
                "Crea chatbots en todos tus canales de atención sin la necesidad de manejar código.",
            status: "Disponible",
            class: "texto-espacio",
        },
        {
            title: "",
            classtitle: "card-title-inbox",
            img: silder3,
            info: "Adhiere tus plataformas internas empresariales, como CRM o ERP.",
            status: "Disponible",
            class: "texto-espacio",
        },
        {
            title: "",
            classtitle: "card-title-inbox",
            img: silder4,
            info:
                "Supervisa a tus agentes en tiempo real y visualiza el estado de su trabajo.",
            status: "Disponible",
            class: "texto-espacio",
        },
        {
            title: "",
            classtitle: "card-title-inbox",
            img: silder5,
            info:
                "Enruta de manera inteligente tus tickets de atención para una mejor productividad.",
            status: "Disponible",
            class: "texto-espacio",
        },
    ],
    contacts: {
        title: "Optimiza tu atención con WhatsApp",
        textbody:
            "Queremos ayudarte a que puedas a utilizar esta herramienta sin problema alguno. Agenda una reunión con nosotros y resolvemos todas tus dudas.",
        btn: "Agenda una reunión gratis",
        href: "/hablemos/",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
}

export default data
